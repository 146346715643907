import React, { useEffect } from 'react';

const DocumentViewer = ({ documentId, onComplete }) => {
  useEffect(() => {
    const handleScroll = (event) => {
      const iframe = event.target;
      const scrollPercentage = 
        (iframe.contentWindow.scrollY / (iframe.contentDocument.body.scrollHeight - iframe.contentWindow.innerHeight)) * 100;
      
      if (scrollPercentage >= 90) {
        onComplete();
      }
    };

    const iframe = document.querySelector('.document-viewer iframe');
    if (iframe) {
      iframe.addEventListener('load', () => {
        try {
          iframe.contentWindow.document.addEventListener('scroll', handleScroll);
        } catch (e) {
          console.log('Cross-origin restrictions prevent scroll tracking');
        }
      });
    }

    return () => {
      const iframe = document.querySelector('.document-viewer iframe');
      if (iframe) {
        try {
          iframe.contentWindow.document.removeEventListener('scroll', handleScroll);
        } catch (e) {
          console.log('Cleanup: Cross-origin restrictions');
        }
      }
    };
  }, [onComplete]);

  const getEmbedUrl = (url) => {
    try {
      // Handle full Google Docs URL
      if (url.includes('docs.google.com/document')) {
        const docId = url.match(/\/d\/([a-zA-Z0-9-_]+)/)?.[1];
        if (docId) {
          return `https://docs.google.com/document/d/${docId}/preview`;
        }
      }
      
      // Handle full Google Slides URL
      if (url.includes('docs.google.com/presentation')) {
        const presentationId = url.match(/\/d\/([a-zA-Z0-9-_]+)/)?.[1];
        if (presentationId) {
          return `https://docs.google.com/presentation/d/${presentationId}/embed?start=false&loop=false&delayms=3000`;
        }
      }

      // If it's just an ID, try to determine the type
      if (!url.includes('docs.google.com')) {
        if (url.includes('presentation')) {
          const cleanId = url.replace('presentation/', '');
          return `https://docs.google.com/presentation/d/${cleanId}/embed?start=false&loop=false&delayms=3000`;
        } else {
          return `https://docs.google.com/document/d/${url}/preview`;
        }
      }

      // Return the original URL if no patterns match
      return url;
    } catch (error) {
      console.error('Error parsing document URL:', error);
      return url;
    }
  };

  return (
    <div className="document-viewer">
      <iframe
        src={getEmbedUrl(documentId)}
        title="Document Viewer"
        width="100%"
        height="600"
        frameBorder="0"
        allowFullScreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      />
    </div>
  );
};

export default DocumentViewer; 