import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { MessageProvider } from './contexts/MessageContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { CourseFormProvider } from './contexts/CourseFormContext';
import Layout from './components/Layout/Layout';
import PrivateRoute from './routes/PrivateRoute';
import AdminRoute from './routes/AdminRoute';
import Login from './components/Authentication/Login';
import UserDashboard from './components/Dashboard/UserDashboard';
import CourseDetail from './components/Course/CourseDetail';
import MessageList from './components/Messaging/MessageList';
import MessageThread from './components/Messaging/MessageThread';
import CoursesPage from './components/Admin/CoursesPage';
import UserManagement from './components/Admin/UserManagement';
import UserCourses from './components/Course/UserCourses';
import CourseForm from './components/Admin/CourseForm';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/global.css';

function App() {
  return (
    <Router>
      <AuthProvider>
        <MessageProvider>
          <NotificationProvider>
            <CourseFormProvider>
              <ToastContainer />
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={
                  <PrivateRoute>
                    <Layout />
                  </PrivateRoute>
                }>
                  <Route index element={<Navigate to="/dashboard" />} />
                  <Route path="dashboard" element={<UserDashboard />} />
                  <Route path="courses" element={<UserCourses />} />
                  <Route path="course/:courseId" element={<CourseDetail />} />
                  <Route path="messages" element={<MessageList />} />
                  <Route path="messages/:threadId" element={<MessageThread />} />
                  <Route path="admin/courses" element={
                    <AdminRoute>
                      <CoursesPage />
                    </AdminRoute>
                  } />
                  <Route path="admin/courses/new" element={
                    <AdminRoute>
                      <CourseForm />
                    </AdminRoute>
                  } />
                  <Route path="admin/courses/:courseId/edit" element={
                    <AdminRoute>
                      <CourseForm />
                    </AdminRoute>
                  } />
                  <Route path="admin/users" element={
                    <AdminRoute>
                      <UserManagement />
                    </AdminRoute>
                  } />
                </Route>
              </Routes>
            </CourseFormProvider>
          </NotificationProvider>
        </MessageProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;