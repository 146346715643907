import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { courseApi } from '../../api/courseApi';
import ModuleList from './ModuleList';
import ModuleContent from './ModuleContent';
import QuizComponent from './QuizComponent';
import ProgressBar from '../Common/ProgressBar';
import CompletionModal from './CompletionModal';
import { FaArrowLeft, FaTrophy, FaCertificate, FaClock, FaCheck, FaBook, FaLock, FaCheckCircle, FaQuestion } from 'react-icons/fa';
import { useAuth } from '../../contexts/AuthContext';
import MessageModal from '../Messaging/MessageModal';

const CourseDetail = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [courseProgress, setCourseProgress] = useState(0);
  const [completedModules, setCompletedModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [quizPassed, setQuizPassed] = useState(false);

  useEffect(() => {
    fetchCourseDetails();
  }, [courseId]);

  useEffect(() => {
    if (course?.modules) {
      // Find the first available module or quiz
      const availableModuleIndex = allModules.findIndex((module, index) => {
        if (module.type === 'quiz') {
          return isModuleAvailable(index) && !quizPassed;
        }
        return isModuleAvailable(index) && !completedModules.includes(module._id);
      });

      if (availableModuleIndex !== -1) {
        setSelectedModule(allModules[availableModuleIndex]);
      }
    }
  }, [course, completedModules, quizPassed]);

  const fetchCourseDetails = async () => {
    try {
      setLoading(true);
      const response = await courseApi.getCourseDetails(courseId);
      setCourse(response.data);
      setCourseProgress(response.data.progress || 0);
      setCompletedModules(response.data.completedModules || []);
      setQuizPassed(response.data.quizPassed || false);
    } catch (err) {
      setError('Failed to load course details');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const calculateProgress = () => {
    if (!course?.modules) return 0;
    
    const totalItems = course.hasQuiz ? course.modules.length + 1 : course.modules.length;
    const completedCount = completedModules.length + (quizPassed ? 1 : 0);
    
    return Math.round((completedCount / totalItems) * 100);
  };

  const handleModuleComplete = async (moduleId) => {
    try {
      // Scroll to top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' });
      
      // Complete the module
      await courseApi.completeModule(courseId, moduleId);
      
      // Update local state
      const updatedCompletedModules = [...completedModules, moduleId];
      setCompletedModules(updatedCompletedModules);
      
      // Check if all modules are completed
      const allModulesCompleted = course.modules.every(module => 
        updatedCompletedModules.includes(module._id)
      );

      // Set progress to 100 if all modules are completed
      const newProgress = allModulesCompleted ? 100 : 
        Math.round((updatedCompletedModules.length / course.modules.length) * 100);

      // Update backend with correct progress
      await courseApi.updateCourseProgress(courseId, {
        progress: newProgress,
        completedModules: updatedCompletedModules,
        isCompleted: allModulesCompleted
      });

      // Refresh course data
      await fetchCourseDetails();

      // Move to next module if available
      const currentIndex = course.modules.findIndex(m => m._id === moduleId);
      if (currentIndex < course.modules.length - 1) {
        setSelectedModule(course.modules[currentIndex + 1]);
      }
    } catch (err) {
      console.error('Failed to mark module as complete:', err);
    }
  };

  useEffect(() => {
    // Update progress whenever completedModules or quizPassed changes
    if (course) {
      const newProgress = calculateProgress();
      setCourseProgress(newProgress);
    }
  }, [completedModules, quizPassed, course]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-50 text-red-600 rounded-md">
        {error}
      </div>
    );
  }

  if (!course) {
    return (
      <div className="p-4 bg-yellow-50 text-yellow-600 rounded-md">
        Course not found
      </div>
    );
  }

  const allModules = [
    ...course.modules,
    ...(course.hasQuiz ? [{
      _id: 'quiz',
      title: 'Final Assessment',
      description: 'Complete this quiz with a score of 80% or higher to finish the course.',
      duration: '30',
      type: 'quiz'
    }] : [])
  ];

  const isModuleAvailable = (moduleIndex) => {
    if (moduleIndex === 0) return true;
    if (moduleIndex === allModules.length - 1 && course.hasQuiz) {
      return course.modules.every(m => completedModules.includes(m._id));
    }
    return completedModules.includes(allModules[moduleIndex - 1]._id);
  };

  const handleModuleSelect = (module, index) => {
    if (completedModules.includes(module._id) || isModuleAvailable(index)) {
      setSelectedModule(module);
    }
  };

  const handleQuizComplete = async (passed) => {
    if (passed) {
      try {
        setQuizPassed(true);
        const newProgress = calculateProgress();
        setCourseProgress(newProgress);
        
        // Update backend with quiz completion
        await courseApi.updateCourseProgress(courseId, {
          quizPassed: true,
          progress: 100, // Set to 100% when quiz is passed
          completedModules: [...completedModules]
        });

        // Fetch updated course details
        await fetchCourseDetails();
      } catch (err) {
        console.error('Failed to update course progress:', err);
      }
    }
  };

  const isCourseCompleted = course.hasQuiz 
    ? completedModules.length === course.modules.length && quizPassed
    : completedModules.length === course.modules.length;

  const getModuleStatus = (module, index) => {
    if (module.type === 'quiz') {
      return quizPassed ? 'completed' : isModuleAvailable(index) ? 'available' : 'locked';
    }
    return completedModules.includes(module._id) ? 'completed' : isModuleAvailable(index) ? 'available' : 'locked';
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Back to Dashboard Link */}
      <div className="mb-6">
        <Link
          to="/dashboard"
          className="inline-flex items-center text-gray-600 hover:text-gray-900"
        >
          <FaArrowLeft className="mr-2" />
          Back to Dashboard
        </Link>
      </div>

      {/* Course Header */}
      <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <h1 className="text-2xl font-bold text-gray-900 mb-2 md:mb-0">
              {course?.title}
            </h1>
            <div className="min-w-[200px] bg-gray-50 rounded-lg p-3 flex items-center justify-center">
              {isCourseCompleted ? (
                <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
                  <FaCheckCircle className="mr-2 h-4 w-4" />
                  Course Completed
                </span>
              ) : (
                <div className="flex items-center">
                  <span className="hidden md:inline text-sm text-gray-600 mr-2">
                    Course Completion:
                  </span>
                  <span className="text-lg font-semibold text-primary-600">
                    {Math.round(courseProgress)}%
                  </span>
                </div>
              )}
            </div>
          </div>
          <p className="text-gray-600">{course?.description}</p>
        </div>
      </div>

      {/* Two Column Layout */}
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Module List - Left Column */}
        <div className="lg:w-1/3">
          <div className="bg-white rounded-lg shadow-sm">
            <div className="p-6 border-b border-gray-200">
              <h2 className="text-xl font-semibold text-gray-900">Modules</h2>
            </div>
            <div className="divide-y divide-gray-200">
              {allModules.map((module, index) => {
                const isCompleted = module.type === 'quiz' ? quizPassed : completedModules.includes(module._id);
                const isAvailable = isModuleAvailable(index);

                return (
                  <button
                    key={module._id}
                    onClick={() => handleModuleSelect(module, index)}
                    disabled={!isAvailable && !isCompleted}
                    className={`w-full text-left p-6 transition-colors ${
                      selectedModule?._id === module._id 
                        ? 'bg-gray-50' 
                        : (isAvailable || isCompleted)
                          ? 'hover:bg-gray-50' 
                          : 'opacity-50 cursor-not-allowed bg-gray-50'
                    }`}
                  >
                    <div className="flex items-start justify-between">
                      <div className="flex-1 min-w-0">
                        <div className="flex items-center gap-2">
                          <h3 className="text-lg font-medium text-gray-900">
                            {module.type === 'quiz' ? module.title : `Module ${index + 1}: ${module.title}`}
                          </h3>
                          {!isAvailable && (
                            <FaLock className="text-gray-400 h-4 w-4" />
                          )}
                        </div>
                        <p className="mt-1 text-sm text-gray-500 line-clamp-2">
                          {module.description}
                        </p>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                          <FaClock className="mr-1.5 h-4 w-4 text-gray-400" />
                          {module.duration} minutes
                        </div>
                      </div>
                      {isCompleted ? (
                        <span className="ml-4 flex-shrink-0">
                          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                            <FaCheck className="mr-1.5 h-4 w-4" />
                            Completed
                          </span>
                        </span>
                      ) : isAvailable ? (
                        <span className="ml-4 flex-shrink-0">
                          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                            Available
                          </span>
                        </span>
                      ) : (
                        <span className="ml-4 flex-shrink-0">
                          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                            Locked
                          </span>
                        </span>
                      )}
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        {/* Module Content - Right Column */}
        <div className="lg:flex-1">
          {selectedModule && (
            <div className="bg-white rounded-lg shadow-sm">
              {/* Add module title header */}
              {selectedModule.type !== 'quiz' && (
                <div className="px-6 py-3 border-b border-gray-200">
                  <h3 className="text-sm font-medium text-gray-900">
                    Module {allModules.findIndex(m => m._id === selectedModule._id) + 1}: {selectedModule.title}
                  </h3>
                </div>
              )}
              
              <div className="p-6">
                {selectedModule.type === 'quiz' ? (
                  quizPassed ? (
                    <div className="text-center py-8">
                      <FaCheckCircle className="mx-auto h-16 w-16 text-green-500 mb-4" />
                      <h2 className="text-2xl font-bold text-gray-900 mb-2">
                        Congratulations!
                      </h2>
                      <p className="text-lg text-gray-600">
                        You have successfully passed this course's assessment.
                      </p>
                    </div>
                  ) : (
                    <QuizComponent
                      courseId={courseId}
                      onQuizComplete={handleQuizComplete}
                    />
                  )
                ) : (
                  <ModuleContent
                    module={selectedModule}
                    onClose={() => setSelectedModule(null)}
                    onComplete={handleModuleComplete}
                    isCompleted={completedModules.includes(selectedModule._id)}
                    courseName={course?.title}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseDetail; 