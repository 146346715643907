import React, { useState, useEffect } from 'react';

const VideoPlayer = ({ url, onComplete }) => {
  const [isYouTube, setIsYouTube] = useState(false);
  const [videoId, setVideoId] = useState('');

  useEffect(() => {
    // Check if URL is a YouTube URL and extract video ID
    const youtubeRegex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
    const match = url.match(youtubeRegex);
    
    if (match && match[1]) {
      setIsYouTube(true);
      setVideoId(match[1]);
    } else {
      setIsYouTube(false);
    }
  }, [url]);

  const handleVideoEnd = () => {
    if (onComplete) {
      onComplete();
    }
  };

  if (isYouTube) {
    return (
      <div className="aspect-w-16 aspect-h-9">
        <iframe
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          onEnded={handleVideoEnd}
        ></iframe>
      </div>
    );
  }

  return (
    <div className="aspect-w-16 aspect-h-9">
      <video
        controls
        className="w-full h-full"
        onEnded={handleVideoEnd}
      >
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer; 