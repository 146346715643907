import api from './axiosConfig';

export const authApi = {
  login: (email, password) => 
    api.post('/auth/login', { email, password }),
  
  register: (userData) => 
    api.post('/auth/register', userData),
  
  getProfile: () => 
    api.get('/auth/profile'),
  
  updateProfile: (userData) => 
    api.put('/auth/profile', userData),
  
  resetPassword: (email) => 
    api.post('/auth/reset-password', { email }),
  
  verifyResetToken: (token) => 
    api.get(`/auth/reset-password/${token}`),
  
  setNewPassword: (token, password) => 
    api.post(`/auth/reset-password/${token}`, { password })
}; 