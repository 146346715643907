import React, { useState, useEffect } from 'react';
import VideoPlayer from './VideoPlayer';
import DocumentViewer from './DocumentViewer';
import WysiwygContent from './WysiwygContent';
import QuizComponent from './QuizComponent';
import { FaCheckCircle, FaTimes, FaCheck, FaQuestion } from 'react-icons/fa';
import MessageModal from '../Messaging/MessageModal';
import { useAuth } from '../../contexts/AuthContext';

const ModuleContent = ({ module, onClose, onComplete, isCompleted, courseName }) => {
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    setContent(module.content);
    setLoading(false);
  }, [module]);

  const handleComplete = () => {
    onComplete(module._id);
  };

  const handleAskQuestion = () => {
    setShowMessageModal(true);
  };

  if (loading) return (
    <div className="flex items-center justify-center p-8">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
    </div>
  );

  return (
    <div className="relative">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-900">{module.title}</h2>
        {isCompleted && (
          <span className="flex items-center text-green-600">
            <FaCheckCircle className="mr-2" /> Completed
          </span>
        )}
      </div>

      <div className="prose max-w-none mb-8">
        <p className="text-gray-600">{module.description}</p>
      </div>

      <div className="mb-8">
        {module.contentType === 'video' && (
          <VideoPlayer 
            url={content} 
            onComplete={handleComplete}
          />
        )}
        {module.contentType === 'document' && (
          <DocumentViewer 
            documentId={content}
            onComplete={handleComplete}
          />
        )}
        {module.contentType === 'wysiwyg' && (
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <div className="prose-container">
              <WysiwygContent content={content} />
            </div>
          </div>
        )}
      </div>

      {module.quiz && !isCompleted && (
        <div className="mt-8 pt-8 border-t border-gray-200">
          <h3 className="text-xl font-semibold mb-4">Module Quiz</h3>
          <QuizComponent
            quiz={module.quiz}
            onComplete={handleComplete}
          />
        </div>
      )}

      <div className="flex flex-col gap-4 mt-6">
        {!isCompleted && (
          <div className="flex items-center">
            <input
              type="checkbox"
              id="confirmCheckbox"
              checked={isConfirmed}
              onChange={(e) => setIsConfirmed(e.target.checked)}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label htmlFor="confirmCheckbox" className="ml-2 text-sm text-gray-600">
              I have reviewed and understand this module material.
            </label>
          </div>
        )}
        
        <div className="flex justify-between items-center">
          <button
            onClick={() => setShowMessageModal(true)}
            className="px-4 py-2 bg-gray-100 text-gray-700 hover:bg-gray-200 rounded-md flex items-center"
          >
            <FaQuestion className="mr-2" /> Ask A Question
          </button>

          {!isCompleted && (
            <button
              onClick={handleComplete}
              disabled={!isConfirmed}
              className={`px-4 py-2 rounded-md text-white ${
                !isConfirmed 
                  ? 'bg-gray-400 cursor-not-allowed' 
                  : 'bg-primary-600 hover:bg-primary-700'
              }`}
            >
              Complete Module
            </button>
          )}
        </div>
      </div>

      {showMessageModal && (
        <MessageModal
          isOpen={showMessageModal}
          onClose={() => setShowMessageModal(false)}
          defaultSubject={`Question about ${courseName} / ${module.title}`}
          readOnlySubject={true}
          recipientRole="admin"
          metadata={{
            type: 'module_question',
            moduleId: module._id,
            moduleName: module.title,
            courseName: courseName
          }}
        />
      )}
    </div>
  );
};

export default ModuleContent;