import React, { useState, useRef, useContext } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FaGripVertical, FaInfoCircle, FaYoutube, FaUpload } from 'react-icons/fa';
import { Editor } from '@tinymce/tinymce-react';
import { courseApi } from '../../api/courseApi';
import { CourseFormContext } from '../../contexts/CourseFormContext';

const ModuleEditor = ({ module, index, onUpdate, onDelete }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [videoType, setVideoType] = useState(module.videoType || 'youtube');
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef(null);
  const { setIsUploading } = useContext(CourseFormContext);

  const handleVideoTypeChange = (type) => {
    setVideoType(type);
    onUpdate({ 
      ...module, 
      videoType: type,
      content: '' // Reset content when changing video type
    });
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!file.type.startsWith('video/')) {
      alert('Please select a valid video file');
      return;
    }

    if (file.size > 500 * 1024 * 1024) {
      alert('File size must be less than 500MB');
      return;
    }

    setUploading(true);
    setUploadProgress(0);
    onUpdate(module, true);

    try {
      const formData = new FormData();
      formData.append('video', file);

      const response = await courseApi.uploadVideo(formData, (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setUploadProgress(Math.round(progress));
      });

      const updatedModule = {
        ...module,
        content: response.data.videoUrl
      };
      
      onUpdate(updatedModule, false);
    } catch (error) {
      console.error('Error uploading video:', error);
      alert('Failed to upload video');
      onUpdate(module, false);
    } finally {
      setUploading(false);
      setUploadProgress(0);
    }
  };

  return (
    <Draggable draggableId={module._id?.toString() || `new-${index}`} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="bg-white border border-gray-200 rounded-lg overflow-hidden"
        >
          <div className="p-4 flex items-center justify-between bg-gray-50">
            <div className="flex items-center gap-3">
              <div {...provided.dragHandleProps} className="text-gray-400">
                <FaGripVertical />
              </div>
              <h4 className="font-medium text-gray-900">Module {index + 1}</h4>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                onClick={() => setIsExpanded(!isExpanded)}
                className="text-sm text-gray-600 hover:text-gray-900"
              >
                {isExpanded ? 'Collapse' : 'Expand'}
              </button>
              <button
                type="button"
                onClick={onDelete}
                className="text-sm text-red-600 hover:text-red-700"
              >
                Delete
              </button>
            </div>
          </div>

          {isExpanded && (
            <div className="p-4 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Title</label>
                <input
                  type="text"
                  value={module.title || ''}
                  onChange={(e) => onUpdate({ ...module, title: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                  value={module.description || ''}
                  onChange={(e) => onUpdate({ ...module, description: e.target.value })}
                  rows={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Content Type</label>
                <select
                  value={module.contentType || 'wysiwyg'}
                  onChange={(e) => {
                    onUpdate({ 
                      ...module, 
                      contentType: e.target.value,
                      content: '',
                      videoType: e.target.value === 'video' ? 'youtube' : undefined
                    });
                    if (e.target.value === 'video') {
                      setVideoType('youtube');
                    }
                  }}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                >
                  <option value="video">Video</option>
                  <option value="document">Document</option>
                  <option value="wysiwyg">Rich Text</option>
                </select>
              </div>

              <div>
                {module.contentType === 'wysiwyg' ? (
                  <>
                    <label className="block text-sm font-medium text-gray-700">Content</label>
                    <Editor
                      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                      value={module.content || ''}
                      init={{
                        height: 400,
                        menubar: true,
                        plugins: [
                          'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                          'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                        ],
                        toolbar: 'undo redo | blocks | ' +
                          'bold italic forecolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help'
                      }}
                      onEditorChange={(content) => onUpdate({ ...module, content })}
                    />
                  </>
                ) : module.contentType === 'document' ? (
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Document URL</label>
                    <div className="mt-1 text-sm text-gray-500 flex items-center mb-2">
                      <FaInfoCircle className="mr-1" />
                      Please ensure the document is published to the web and shared with "Anyone with the link can view"
                    </div>
                    <input
                      type="text"
                      value={module.content || ''}
                      onChange={(e) => onUpdate({ ...module, content: e.target.value })}
                      placeholder="Paste the full Google Doc/Slides URL here"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                    />
                  </div>
                ) : module.contentType === 'video' ? (
                  <div className="space-y-4">
                    <div className="flex space-x-4">
                      <button
                        type="button"
                        onClick={() => handleVideoTypeChange('youtube')}
                        className={`flex items-center px-4 py-2 rounded-md ${
                          videoType === 'youtube'
                            ? 'bg-primary-600 text-white'
                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                        }`}
                      >
                        <FaYoutube className="mr-2" /> YouTube Video
                      </button>
                      <button
                        type="button"
                        onClick={() => handleVideoTypeChange('uploaded')}
                        className={`flex items-center px-4 py-2 rounded-md ${
                          videoType === 'uploaded'
                            ? 'bg-primary-600 text-white'
                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                        }`}
                      >
                        <FaUpload className="mr-2" /> Upload Video
                      </button>
                    </div>

                    {videoType === 'youtube' ? (
                      <div>
                        <label className="block text-sm font-medium text-gray-700">YouTube Video URL</label>
                        <div className="mt-1 text-sm text-gray-500 flex items-center mb-2">
                          <FaInfoCircle className="mr-1" />
                          Paste the full YouTube video URL
                        </div>
                        <input
                          type="text"
                          value={module.content || ''}
                          onChange={(e) => onUpdate({ ...module, content: e.target.value })}
                          placeholder="https://www.youtube.com/watch?v=..."
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                        />
                      </div>
                    ) : (
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Upload Video</label>
                        <div className="mt-1 text-sm text-gray-500 flex items-center mb-2">
                          <FaInfoCircle className="mr-1" />
                          Maximum file size: 500MB. Supported formats: MP4, WebM
                        </div>
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleFileUpload}
                          accept="video/*"
                          className="hidden"
                        />
                        <button
                          type="button"
                          onClick={() => fileInputRef.current?.click()}
                          disabled={uploading}
                          className="flex items-center px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
                        >
                          <FaUpload className="mr-2" />
                          {uploading ? `Uploading... ${uploadProgress}%` : 'Select Video'}
                        </button>
                        {module.content && (
                          <div className="mt-2 text-sm text-gray-500">
                            Current video: {module.content}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Duration (minutes)</label>
                <input
                  type="number"
                  value={module.duration || 30}
                  onChange={(e) => onUpdate({ ...module, duration: parseInt(e.target.value) })}
                  min="1"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                />
              </div>
            </div>
          )}
          {uploading && (
            <div className="mt-2">
              <div className="text-sm text-gray-600">
                Uploading... {uploadProgress}%
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div 
                  className="bg-primary-600 h-2.5 rounded-full" 
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default ModuleEditor; 