import React, { useState, useEffect } from 'react';
import { courseApi } from '../../api/courseApi';
import { toast } from 'react-toastify';
import { FaCheckCircle } from 'react-icons/fa';

const QuizComponent = ({ courseId, onQuizComplete }) => {
  const [quiz, setQuiz] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [passed, setPassed] = useState(false);

  useEffect(() => {
    if (!passed) {
      fetchQuiz();
    }
  }, [courseId, passed]);

  const fetchQuiz = async () => {
    try {
      const response = await courseApi.getCourseDetails(courseId);
      if (response.data.quiz && response.data.quiz.questions) {
        setQuiz(response.data.quiz);
      } else {
        setError('No quiz available for this course');
      }
    } catch (err) {
      setError('Failed to load quiz');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await courseApi.submitQuizAttempt(courseId, {
        answers: Object.entries(answers).map(([questionId, selectedOption]) => ({
          questionId,
          selectedOption
        }))
      });

      if (response.data.passed) {
        setPassed(true);
        toast.success(`Congratulations! You passed with ${response.data.score}%`);
        await onQuizComplete(true);
      } else {
        toast.error(`You scored ${response.data.score}%. Required: 80%. Please try again.`);
        setAnswers({});
        setCurrentQuestion(0);
        fetchQuiz();
      }
    } catch (err) {
      console.error('Failed to submit quiz:', err);
      setError('Failed to submit quiz');
    }
  };

  const handleOptionSelect = (questionId, optionId) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: optionId
    }));
  };

  if (loading) return <div>Loading quiz...</div>;
  if (error) return <div className="text-red-600">{error}</div>;
  if (!quiz || !quiz.questions || quiz.questions.length === 0) {
    return <div>No quiz available</div>;
  }

  const currentQ = quiz.questions[currentQuestion];
  if (!currentQ) return <div>Error loading question</div>;

  if (passed) {
    return (
      <div className="text-center py-8">
        <FaCheckCircle className="mx-auto h-16 w-16 text-green-500 mb-4" />
        <h2 className="text-2xl font-bold text-gray-900 mb-2">
          Congratulations!
        </h2>
        <p className="text-lg text-gray-600">
          You have successfully passed this course's assessment.
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Final Assessment</h2>
        <span className="text-gray-600">
          Question {currentQuestion + 1} of {quiz.questions.length}
        </span>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h3 className="text-lg font-medium mb-4">{currentQ.text}</h3>
        <div className="space-y-3">
          {currentQ.options.map(option => (
            <label
              key={option._id}
              className={`flex items-center p-4 border rounded-md cursor-pointer transition-colors ${
                answers[currentQ._id] === option._id
                  ? 'border-primary-500 bg-primary-50'
                  : 'border-gray-200 hover:bg-gray-50'
              }`}
            >
              <input
                type="radio"
                name={`question-${currentQ._id}`}
                checked={answers[currentQ._id] === option._id}
                onChange={() => handleOptionSelect(currentQ._id, option._id)}
                className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300"
              />
              <span className="ml-3">{option.text}</span>
            </label>
          ))}
        </div>
      </div>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={() => setCurrentQuestion(prev => prev - 1)}
          disabled={currentQuestion === 0}
          className="px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 disabled:opacity-50"
        >
          Previous
        </button>
        
        {currentQuestion < quiz.questions.length - 1 ? (
          <button
            type="button"
            onClick={() => setCurrentQuestion(prev => prev + 1)}
            disabled={!answers[currentQ._id]}
            className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 disabled:opacity-50"
          >
            Next
          </button>
        ) : (
          <button
            type="button"
            onClick={handleSubmit}
            disabled={Object.keys(answers).length !== quiz.questions.length}
            className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 disabled:opacity-50"
          >
            Submit Quiz
          </button>
        )}
      </div>
    </div>
  );
};

export default QuizComponent; 