import React from 'react';
import DOMPurify from 'dompurify';

const WysiwygContent = ({ content }) => {
  return (
    <div 
      className="prose prose-lg max-w-none prose-headings:font-bold prose-h1:text-3xl prose-h2:text-2xl prose-h3:text-xl prose-p:text-gray-600 prose-a:text-primary-600 hover:prose-a:text-primary-500 prose-img:rounded-lg prose-pre:bg-gray-800 prose-pre:text-gray-100"
      dangerouslySetInnerHTML={{ 
        __html: DOMPurify.sanitize(content, { 
          ADD_TAGS: ['iframe'],
          ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling']
        }) 
      }}
    />
  );
};

export default WysiwygContent; 