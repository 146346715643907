import React, { useState } from 'react';
import { Link, useLocation, useNavigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import NotificationCenter from '../Notification/NotificationCenter';
import { 
  FaHome, 
  FaBook, 
  FaEnvelope, 
  FaUsers, 
  FaSignOutAlt,
  FaBars,
  FaTimes
} from 'react-icons/fa';

const LOGO_URL = "https://mhg-sig.s3.us-west-1.amazonaws.com/image-1731093328648.png";

const Layout = () => {
  const { user, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const isActive = (path) => {
    return location.pathname.startsWith(path);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const navItems = [
    {
      to: '/dashboard',
      icon: FaHome,
      label: 'Dashboard',
      show: true
    },
    {
      to: '/admin/courses',
      icon: FaBook,
      label: 'Manage Courses',
      show: user?.role === 'admin'
    },
    {
      to: '/messages',
      icon: FaEnvelope,
      label: 'Messages',
      show: true
    },
    {
      to: '/admin/users',
      icon: FaUsers,
      label: 'Users',
      show: user?.role === 'admin'
    }
  ];

  if (!user) {
    return <Outlet />;
  }

  return (
    <div className="min-h-screen flex flex-col">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            {/* Logo and Title */}
            <div className="flex items-center">
              <Link to="/dashboard" className="flex-shrink-0 flex items-center gap-4">
                <img 
                  src={LOGO_URL}
                  alt="Drivonic Logo" 
                  className="w-full max-h-[50px] object-contain"
                />
                <div className="h-6 w-px bg-gray-200 hidden sm:block"></div>
                <span className="text-xl font-bold text-gray-900 hidden sm:block">
                  Training
                </span>
              </Link>

              {/* Desktop Navigation */}
              <nav className="hidden md:flex ml-10 space-x-8">
                {navItems.filter(item => item.show).map(item => {
                  const Icon = item.icon;
                  return (
                    <Link
                      key={item.to}
                      to={item.to}
                      className={`flex items-center space-x-2 px-3 py-2 rounded-md text-sm font-medium ${
                        isActive(item.to)
                          ? 'text-primary-600 bg-primary-50'
                          : 'text-gray-600 hover:text-primary-600'
                      }`}
                    >
                      <Icon /> <span>{item.label}</span>
                    </Link>
                  );
                })}
              </nav>
            </div>

            {/* Right side icons */}
            <div className="flex items-center space-x-4">
              <NotificationCenter />
              {/* Desktop Logout */}
              <button
                onClick={handleLogout}
                className="hidden md:flex items-center text-gray-600 hover:text-gray-900"
              >
                <FaSignOutAlt className="mr-2" />
                Logout
              </button>
              {/* Mobile Menu Button */}
              <button
                onClick={() => setIsMobileMenuOpen(true)}
                className="md:hidden p-2 rounded-md text-gray-600 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
              >
                <FaBars className="h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div 
          className="md:hidden fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity" 
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}

      {/* Mobile Menu Slide-in */}
      <div 
        className={`md:hidden fixed inset-y-0 right-0 w-64 bg-white shadow-xl transform transition-transform duration-300 ease-in-out z-50 ${
          isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="flex flex-col h-full">
          <div className="p-4 border-b border-gray-200 flex justify-between items-center">
            <h2 className="text-lg font-semibold text-gray-900">Menu</h2>
            <button
              onClick={() => setIsMobileMenuOpen(false)}
              className="p-2 rounded-md text-gray-600 hover:text-gray-900 focus:outline-none"
            >
              <FaTimes className="h-5 w-5" />
            </button>
          </div>

          <nav className="flex-1 px-4 py-4 space-y-2">
            {navItems.filter(item => item.show).map(item => {
              const Icon = item.icon;
              return (
                <Link
                  key={item.to}
                  to={item.to}
                  onClick={() => setIsMobileMenuOpen(false)}
                  className={`flex items-center px-3 py-2 rounded-md text-sm font-medium ${
                    isActive(item.to)
                      ? 'text-primary-600 bg-primary-50'
                      : 'text-gray-600 hover:text-primary-600'
                  }`}
                >
                  <Icon className="mr-3 h-5 w-5" />
                  {item.label}
                </Link>
              );
            })}
          </nav>

          <div className="p-4 border-t border-gray-200">
            <button
              onClick={() => {
                setIsMobileMenuOpen(false);
                handleLogout();
              }}
              className="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-900"
            >
              <FaSignOutAlt className="mr-3 h-5 w-5" />
              Logout
            </button>
          </div>
        </div>
      </div>

      <main className="flex-1 bg-gray-50">
        <Outlet />
      </main>

      <footer className="bg-white border-t border-gray-200">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center mb-4">
            <img 
              src={LOGO_URL}
              alt="Drivonic Logo" 
              className="w-full max-h-[40px] object-contain"
            />
          </div>
          <p className="text-center text-sm text-gray-500">
            © {new Date().getFullYear()} MHG. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Layout; 