import React from 'react';
import { Link } from 'react-router-dom';
import { FaPlay } from 'react-icons/fa';

const CourseCard = ({ course, isCompleted }) => {
  // Calculate progress including quiz if course has one
  const calculateProgress = () => {
    if (!course) return 0;
    
    const totalItems = course.hasQuiz ? course.modules.length + 1 : course.modules.length;
    const completedItems = course.completedModules.length + (course.quizPassed ? 1 : 0);
    
    return Math.round((completedItems / totalItems) * 100);
  };

  const progress = calculateProgress();

  return (
    <div className={`bg-white rounded-lg shadow-sm p-6 ${
      isCompleted ? 'border-2 border-green-500' : ''
    }`}>
      <h2 className="text-xl font-semibold mb-2">{course.title}</h2>
      <p className="text-gray-600 mb-4 line-clamp-4 h-[100px] overflow-hidden">
        {course.description}
      </p>
      
      <div className="mb-4">
        <div className="flex justify-between items-center mb-1">
          <span>Progress</span>
          <span>{progress}%</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div
            className={`rounded-full h-2 ${
              isCompleted ? 'bg-green-500' : 'bg-primary-600'
            }`}
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>

      <Link
        to={`/course/${course._id}`}
        className={`w-full inline-flex justify-center items-center px-4 py-2 rounded-md ${
          isCompleted 
            ? 'bg-green-600 hover:bg-green-700 text-white'
            : 'bg-primary-600 hover:bg-primary-700 text-white'
        }`}
      >
        <FaPlay className="mr-2" />
        {isCompleted ? 'Review Course' : 'Continue'}
      </Link>
    </div>
  );
};

export default CourseCard; 