import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { courseApi } from '../../api/courseApi';
import ProgressBar from '../Common/ProgressBar';
import { FaPlay, FaCheck } from 'react-icons/fa';

const UserCourses = () => {
  const [courses, setCourses] = useState({
    inProgress: [],
    completed: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await courseApi.getUserCourses();
      setCourses({
        inProgress: response.data.inProgress || [],
        completed: response.data.completed || []
      });
    } catch (err) {
      setError('Failed to load courses');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return (
    <div className="flex justify-center items-center min-h-[400px]">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
    </div>
  );

  if (error) return (
    <div className="text-red-600 bg-red-50 p-4 rounded-md">
      {error}
    </div>
  );

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">My Courses</h1>

      {/* In Progress Courses */}
      <section className="mb-12">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">In Progress</h2>
        {courses.inProgress?.length === 0 ? (
          <p className="text-gray-500">No courses in progress.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {courses.inProgress?.map(course => (
              <Link
                key={course._id}
                to={`/course/${course._id}`}
                className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200"
              >
                <div className="p-6">
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">
                    {course.title}
                  </h3>
                  <p className="text-gray-600 mb-4 line-clamp-2">
                    {course.description}
                  </p>
                  <div className="mb-4">
                    <div className="flex justify-between text-sm text-gray-600 mb-1">
                      <span>Progress</span>
                      <span>{Math.round(course.progress)}%</span>
                    </div>
                    <ProgressBar progress={course.progress} height={4} />
                  </div>
                  <button className="flex items-center justify-center w-full px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 transition-colors">
                    <FaPlay className="mr-2" /> Continue
                  </button>
                </div>
              </Link>
            ))}
          </div>
        )}
      </section>

      {/* Completed Courses */}
      <section>
        <h2 className="text-2xl font-bold text-gray-900 mb-6">Completed</h2>
        {courses.completed?.length === 0 ? (
          <p className="text-gray-500">No completed courses yet.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {courses.completed?.map(course => (
              <Link
                key={course._id}
                to={`/course/${course._id}`}
                className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200"
              >
                <div className="p-6">
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">
                    {course.title}
                  </h3>
                  <p className="text-gray-600 mb-4 line-clamp-2">
                    {course.description}
                  </p>
                  <div className="flex items-center justify-between text-green-600">
                    <span className="flex items-center">
                      <FaCheck className="mr-2" /> Completed
                    </span>
                    <span className="text-sm">
                      {new Date(course.completedAt).toLocaleDateString()}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
      </section>
    </div>
  );
};

export default UserCourses; 