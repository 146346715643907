import api from './axiosConfig';

export const notificationApi = {
  getNotifications: () => 
    api.get('/notifications'),
  
  getUnreadCount: () =>
    api.get('/notifications/unread-count'),
  
  markAsRead: (notificationId) => 
    api.post(`/notifications/${notificationId}/read`),
  
  markAllAsRead: () => 
    api.post('/notifications/mark-all-read'),
  
  deleteNotification: (notificationId) => 
    api.delete(`/notifications/${notificationId}`)
}; 