import React, { useState, useEffect } from 'react';
import { courseApi } from '../../api/courseApi';
import CourseCard from '../Course/CourseCard';
import { useAuth } from '../../contexts/AuthContext';

const UserDashboard = () => {
  const [courses, setCourses] = useState({
    available: [],
    inProgress: [],
    completed: []
  });
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await courseApi.getUserCourses();
      const coursesData = Array.isArray(response.data) ? response.data : 
        (response.data.completed || []).concat(response.data.inProgress || []);

      // If user is admin, show all courses, otherwise filter by userType
      const userAccessibleCourses = user.role === 'admin' 
        ? coursesData 
        : coursesData.filter(course => 
            course.userGroups.includes(user.userType)
          );

      const sortedCourses = {
        completed: userAccessibleCourses.filter(course => 
          course.progress === 100 && (!course.hasQuiz || course.quizPassed)
        ),
        inProgress: userAccessibleCourses.filter(course => 
          (course.progress > 0 && course.progress < 100) || 
          (course.progress === 100 && course.hasQuiz && !course.quizPassed)
        ),
        available: userAccessibleCourses.filter(course => 
          course.progress === 0
        )
      };

      console.log('User role:', user.role);
      console.log('User type:', user.userType);
      console.log('Available courses:', userAccessibleCourses);
      
      setCourses(sortedCourses);
    } catch (err) {
      console.error('Error fetching courses:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* In Progress Section */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-6">In Progress</h2>
        <div className="bg-white rounded-lg shadow-sm p-8">
          {courses.inProgress.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {courses.inProgress.map(course => (
                <CourseCard 
                  key={course._id} 
                  course={course}
                />
              ))}
            </div>
          ) : (
            <p className="text-gray-600">No courses in progress.</p>
          )}
        </div>
      </section>

      {/* Available Courses Section */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-6">Available Courses</h2>
        <div className="bg-white rounded-lg shadow-sm p-8">
          {courses.available.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {courses.available.map(course => (
                <CourseCard 
                  key={course._id} 
                  course={course}
                />
              ))}
            </div>
          ) : (
            <p className="text-gray-600">No courses available.</p>
          )}
        </div>
      </section>

      {/* Completed Section */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-6">Completed</h2>
        <div className="bg-white rounded-lg shadow-sm p-8">
          {courses.completed.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {courses.completed.map(course => (
                <CourseCard 
                  key={course._id} 
                  course={course}
                  isCompleted={true}
                />
              ))}
            </div>
          ) : (
            <p className="text-gray-600">No completed courses yet.</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default UserDashboard; 