import api from './axiosConfig';

export const messageApi = {
  getThreads: (params = {}) => 
    api.get('/messages', { params }),
  
  getThread: (threadId) => 
    api.get(`/messages/${threadId}`),
  
  sendMessage: (data) => 
    api.post('/messages/send', data),
  
  replyToMessage: (threadId, data) => 
    api.post(`/messages/${threadId}/reply`, data),
  
  markAsRead: (threadId) => 
    api.post(`/messages/${threadId}/read`),
  
  archiveThread: (threadId) => 
    api.post(`/messages/${threadId}/archive`),
  
  deleteThread: (threadId) => 
    api.delete(`/messages/${threadId}`),
  
  getUnreadCount: () => 
    api.get('/messages/unread-count')
}; 