import React, { createContext, useState } from 'react';

export const CourseFormContext = createContext();

export const CourseFormProvider = ({ children }) => {
  const [isUploading, setIsUploading] = useState(false);

  return (
    <CourseFormContext.Provider value={{ isUploading, setIsUploading }}>
      {children}
    </CourseFormContext.Provider>
  );
}; 