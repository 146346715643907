import api from './axiosConfig';

export const userApi = {
  getAllUsers: () => 
    api.get('/users/admin/users'),
  
  createUser: (userData) => 
    api.post('/users/admin/users', userData),
  
  updateUser: (userId, data) => 
    api.put(`/users/admin/users/${userId}`, data),
  
  deleteUser: (userId) => 
    api.delete(`/users/admin/users/${userId}`),
  
  getUserProfile: () => 
    api.get('/users/profile'),
  
  updateUserProfile: (data) => 
    api.put('/users/profile', data)
}; 