import React from 'react';

const ProgressBar = ({ progress, height = 8, showLabel = true }) => {
  return (
    <div className="progress-container">
      <div 
        className="progress-bar"
        style={{ height: `${height}px` }}
      >
        <div 
          className="progress-fill"
          style={{ width: `${progress}%` }}
        />
      </div>
      {showLabel && (
        <span className="progress-label">{Math.round(progress)}%</span>
      )}
    </div>
  );
};

export default ProgressBar; 