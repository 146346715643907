import api from './axiosConfig';

export const courseApi = {
  getUserCourses: () => api.get('/courses/user-courses'),
  
  getCourseDetails: (courseId) => api.get(`/courses/${courseId}/details`),
  
  getModuleContent: (courseId, moduleId) => 
    api.get(`/courses/${courseId}/modules/${moduleId}`),
  
  updateModuleProgress: (courseId, moduleId, data) => 
    api.post(`/courses/${courseId}/modules/${moduleId}/progress`, data),
  
  // Quiz endpoints
  getQuiz: (quizId) => 
    api.get(`/quizzes/${quizId}`),
  
  submitQuiz: (quizId, data) => 
    api.post(`/quizzes/${quizId}/submit`, data),
  
  createQuiz: (data) => 
    api.post('/quizzes', data),
  
  updateQuiz: (quizId, data) => 
    api.put(`/quizzes/${quizId}`, data),
  
  deleteQuiz: (quizId) => 
    api.delete(`/quizzes/${quizId}`),
  
  getQuizAttempts: (quizId) => 
    api.get(`/quizzes/${quizId}/attempts`),
  
  // Admin endpoints
  getAllCourses: () => 
    api.get('/courses/admin/all'),
  
  getAdminStats: () => 
    api.get('/courses/admin/stats'),
  
  createCourse: (data) => 
    api.post('/courses/admin/create', data),
  
  updateCourse: (courseId, data) => 
    api.put(`/courses/admin/${courseId}`, data),
  
  deleteCourse: (courseId) => 
    api.delete(`/courses/admin/${courseId}`),
  
  getCourseStudents: (courseId) => 
    api.get(`/courses/admin/${courseId}/students`),
  
  addModule: (courseId, data) => 
    api.post(`/courses/admin/${courseId}/modules`, data),
  
  updateModule: (courseId, moduleId, data) => 
    api.put(`/courses/admin/${courseId}/modules/${moduleId}`, data),
  
  deleteModule: (courseId, moduleId) => 
    api.delete(`/courses/admin/${courseId}/modules/${moduleId}`),
  
  reorderModules: (courseId, data) => 
    api.post(`/courses/admin/${courseId}/modules/reorder`, data),
  
  // Certificate endpoints
  generateCertificate: (courseId) => 
    api.post(`/certificates/${courseId}/generate`),
  
  getCertificate: (certificateId) => 
    api.get(`/certificates/${certificateId}`),
  
  getUserCertificates: () => 
    api.get('/certificates/user/all'),
  
  downloadCertificate: (certificateId) => 
    api.get(`/certificates/${certificateId}/download`, { responseType: 'blob' }),
  
  getCourseProgress: (courseId) => 
    api.get(`/courses/${courseId}/progress`),
  
  completeModule: (courseId, moduleId) => 
    api.post(`/courses/${courseId}/modules/${moduleId}/complete`),
  
  // For dashboard - get all courses
  getDashboardCourses: () => 
    api.get('/courses/dashboard-courses'),
  
  uploadVideo: (formData, onUploadProgress) => 
    api.post('/courses/upload-video', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: onUploadProgress
    }),
  
  getCourse: (courseId) => 
    api.get(`/courses/admin/${courseId}`),
  
  deleteUser: (courseId) => 
    api.delete(`/courses/admin/${courseId}`),
  
  // Add these new quiz-related methods
  submitQuizAttempt: (courseId, data) => 
    api.post(`/courses/${courseId}/quiz/submit`, data),
  
  getQuizAttempts: (courseId) => 
    api.get(`/courses/${courseId}/quiz/attempts`),
  
  getCourseQuiz: (courseId) =>
    api.get(`/courses/${courseId}/quiz`),
  
  updateCourseProgress: (courseId, data) =>
    api.post(`/courses/${courseId}/progress`, {
      ...data,
      quizCompleted: data.quizPassed
    })
}; 